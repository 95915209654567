<template>
    <div class="extra__container">
        
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.extra__container {
    min-height: 100vh;
    height: 90vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>