<template>
    <div class="footer__container">
        <div class="tagline">
            <p>Designed & Developed by Daniel Rogerson</p>
        </div>
    </div>
</template>

<script></script>

<style scoped>
.footer__container{
    height: 5em;
    display: flex;
    width: 100vw;
    background-color: #1b1d21;
}
.tagline{
padding-left: 2em;
}
</style>